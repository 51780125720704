.contact-container {
  margin-right: 150px;
  margin-left: 150px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
}

.content {
  position: relative;
  z-index: 100;
  margin-top: -600px;
  margin-bottom: 100px;
  align-self: flex-start;
}
.contact-text {
  max-width: 525px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.contact-icons {
  max-width: 70px;
  max-height: 80px;
  margin-right: 15px;
}

@media screen and (max-width: 810px) {
  .contact-container {
    margin-left: 50px;
    margin-right: 50px;
  }
}

@media screen and (max-width: 441px) {
  .contact-container {
    margin-left: 25px;
    margin-right: 25px;
  }
  .contact-icons {
    max-width: 60px;
    max-height: 70px;
  }
}

@media screen and (max-width: 389px) {
  .contact-container {
    margin-left: 25px;
    margin-right: 25px;
  }
  .contact-icons {
    max-width: 50px;
    max-height: 60px;
  }
}
