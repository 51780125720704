.App {
  /* text-align: center; */
  background: linear-gradient(
      180deg,
      rgba(231, 23, 21, 0.25) 19.09%,
      rgba(240, 92, 91, 0.1525) 56.49%,
      rgba(7, 57, 72, 0.25) 117.87%
    ),
    #073948;
  color: white;
  overflow-x: hidden !important;
}

body::-webkit-scrollbar {
  display: none;
}

h1 {
  font-family: 'Syne';
  font-weight: 720;
  font-size: 48px;
}

h2 {
  font-family: 'Syne';
  font-weight: 720;
  font-size: 36px;
}

h3 {
  font-family: 'Syne';
  font-weight: 720;
  font-size: 24px;
}

p {
  font-family: 'Montserrat';
  font-size: 16px;
}

/* THIS IS WHERE THE NAV BAR STYLES CAN GO */

#nav-link {
  font-family: 'Syne';
  font-weight: 720;
  font-size: 1.25em;
  color: #ffeeed !important;
  margin-left: 1.5vh;
}

.logo {
  margin-left: 15px;
  width: 50px;
  height: 50px;
}

.nav-right {
  margin-left: auto;
  margin-right: 0;
}

#navbar-toggler,
#navbar-toggler:focus,
#navbar-toggler:active,
#navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
  border: none;
}

.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(240, 92, 91)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

#footer {
  background-color: #26303a;
  padding-top: 15px;
  padding-bottom: 20px;
}

.copyright {
  font-family: 'Syne';
  font-weight: 720;
  font-size: 13px;
  width: 100%;
  text-align: end;
  margin-right: 20px;
}

#social-links {
  display: flex;
  flex-direction: row;
}

.fade-in {
  animation: fadein 1s;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.scroll-top {
  border-width: 0px;
  background-color: transparent;
  font-size: 20px;
  position: fixed;
  right: 6vh;
  bottom: 16vh;
  z-index: 2;
}

.scroll-container {
  position: relative;
  z-index: 2;
}

#hexagon-scroll {
  width: 60px;
  height: 50px;
}

.spacer {
  height: 5vw;
}

img {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

@media (max-width: 500px) {
  #nav-link {
    font-size: 1em;
    margin-left: 1.5vh;
  }
}

@media (max-width: 400px) {
  #nav-link {
    font-size: 0.87em;
    margin-left: 1.5vh;
  }
}

.red-button {
  box-sizing: border-box;
  width: 150px;
  height: 48px;
  left: 134px;
  top: 597px;
  background: linear-gradient(
    180deg,
    rgba(240, 92, 91, 0.75) 0%,
    rgba(240, 92, 91, 0.2325) 100%
  );
  border: 1px solid rgba(240, 92, 91, 0.1);
  box-shadow:
    0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 0px 12px 7px rgba(46, 56, 68, 0.25);
  border-radius: 10px;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #fdeceb;
}

.red-button:active,
.red-button:hover {
  background: #9b3837 !important;
  border-color: transparent !important;
}
