@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap');

.desktop-hex {
  width: auto;
  height: auto;
  position: absolute;
  right: -4.7%;
  top: -2%;
  scale: 1.05;
  pointer-events: none;
  float: right;
  z-index: 0;
}

.main-text {
  font-size: 48px;
  display: inline;
  color: #ffeeed;
}

.main-text-box {
  margin-top: 200px;
  margin-bottom: 120px;
  max-width: 550px;
}

.sub-text {
  width: 80%;
  padding-top: 20px;
  font-size: 20px;
}

.mobile-show {
  display: none !important;
}

.header-container {
  background-color: rgba(112, 105, 113, 0.7);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  backdrop-filter: blur(8px);
}

.body-container {
  background-color: rgba(87, 87, 95, 0.7);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  backdrop-filter: blur(8px);
}

.pm-jobs-table {
  border-radius: 10px;
  margin-block: 50px;
  padding: 0;
  position: relative;
  z-index: 1;
  margin-bottom: 100px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  border: 0.5px solid #aaa3a6;
}

.table-headers {
  font-family: 'Syne';
  font-size: 16;
  color: #ffeeed;
  padding-block: 45px;
  text-align: left;
  overflow-wrap: anywhere;
}

.table-items {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19.5px;
  text-transform: capitalize;
  color: #ffeeed;
  padding-block: 40px;
  text-align: left;
  border-bottom: 0.5px solid #ffffff;
}

a.role-links:link {
  text-decoration: none;
  color: #ffeeed;
  text-decoration: underline;
}

a.role-links:visited {
  text-decoration: none;
  color: #9b9b9b;
  text-decoration: underline;
}

@media (max-width: 991px) {
  .main-text {
    font-size: 42px;
  }

  .main-text-box {
    max-width: 320px;
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .sub-text {
    font-size: 16px;
    width: 100%;
  }

  .table-headers {
    font-size: 14px;
  }

  .table-items {
    font-size: 14px;
  }

  .desktop-hex {
    width: auto;
    height: auto;
    position: absolute;
    right: -20%;
    top: -2%;
    scale: 1.05;
    pointer-events: none;
    float: right;
    z-index: 0;
  }
}

@media (min-width: 767px) {
  .titleImage {
    scale: 0.85;
    margin-bottom: 20px;
    position: relative;
  }

  .head-container {
    margin-left: 5vw;
    margin-top: 10%;
    margin-bottom: 5%;
  }
}

@media (max-width: 767px) {
  .main-text {
    font-size: 24px;
  }

  .main-text-box {
    max-width: 290px;
  }

  .sub-text {
    font-size: 14px;
  }

  .table-headers {
    font-size: 12px;
  }

  .table-items {
    font-size: 12px;
  }

  .pm-jobs-table {
    margin-bottom: 70px;
  }

  .mobile-hide {
    display: none !important;
  }
  .mobile-show {
    display: flex !important;
  }
  .table-headers {
    font-size: 16px;
  }

  .table-headers {
    padding-block: 20px;
  }

  .table-items {
    padding-block: 25px;
  }

  .titleImage {
    scale: 0.7;
    margin-bottom: 20px;
    position: relative;
  }

  .head-container {
    margin-left: -5vw;
    margin-top: 10%;
    margin-bottom: 15%;
  }

  .desktop-hex {
    width: auto;
    height: auto;
    position: absolute;
    right: -50%;
    top: -4%;
    scale: 1;
    pointer-events: none;
    float: right;
    z-index: 0;
  }
}

@media (max-width: 1399px) {
  .desktop-hex {
    width: auto;
    height: auto;
    position: absolute;
    right: -4.7%;
    top: -2%;
    scale: 1.05;
    pointer-events: none;
    float: right;
    z-index: 0;
  }
}

@media (max-width: 1199px) {
  .desktop-hex {
    width: auto;
    height: auto;
    position: absolute;
    right: -4.7%;
    top: -2%;
    scale: 1.05;
    pointer-events: none;
    float: right;
    z-index: 0;
  }
}
@media (max-width: 650px) {
  .desktop-hex {
    width: auto;
    height: auto;
    position: absolute;
    right: -70%;
    top: -4vh;
    scale: 0.95;
    pointer-events: none;
    float: right;
    z-index: 0;
  }
}
