@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap');

html {
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: rgb(222, 222, 222);
}

.about-container {
  position: relative;
  min-height: 830px;
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.about-caption {
  position: absolute;
  top: 2rem;
  margin-top: 200px;
  margin-bottom: 100px;
  margin-right: 20%;
  width: 60%;
}

.mission-vision-row {
  margin-top: -205px;
  z-index: 10;
}

.what-we-do-row {
  margin-top: -50px;
  z-index: 10;
  display: flex;
  justify-content: center;
  height: 425px;
}

.about-us-card {
  margin-top: -200;
  padding: 50px;
  height: 325px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-bg {
  padding: 50px;
  background-color: rgb(83, 71, 71);
  border-radius: 10px;
}

.card-title-parent {
  display: flex;
  justify-content: center;
}

.card-title {
  z-index: 100;
  position: relative;
  width: 250px;
  height: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  background: linear-gradient(
      0deg,
      rgba(240, 92, 91, 0.7),
      rgba(240, 92, 91, 0.7)
    ),
    linear-gradient(
      180deg,
      rgba(255, 238, 237, 0.04) 0%,
      rgba(7, 57, 72, 0.124) 100%
    );
}

.big-card-title {
  z-index: 100;
  position: relative;
  width: 400px;
  height: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  background: linear-gradient(
      0deg,
      rgba(240, 92, 91, 0.7),
      rgba(240, 92, 91, 0.7)
    ),
    linear-gradient(
      180deg,
      rgba(255, 238, 237, 0.04) 0%,
      rgba(7, 57, 72, 0.124) 100%
    );
}

.about-card-body {
  margin-top: -30px;
  z-index: 99;
  height: 220px;
  padding: 35px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(204, 171, 170, 0.414);
}

.big-about-card-body {
  margin-top: -30px;
  z-index: 99;
  height: 420px;
  padding: 60px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(204, 171, 170, 0.414);
}

.about-header-text {
  font-family: 'Syne';
  font-weight: 700;
  font-style: unset;
  font-size: 35px;
  text-align: center;
}

.mission-text {
  text-align: center;
  font-family: 'Montserrat';
  font-size: 17px;
  color: white;
}

.big-card-text {
  font-family: 'Montserrat';
  font-size: 17px;
  color: white;
}

.logo-row {
  position: relative;
  z-index: 101;
  margin-top: -140px;
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
}

.logo-container {
  display: flex;
  justify-content: center;
}

.meet-team-container {
  position: relative;
  min-height: 830px;
  display: flex;
  margin-top: 100px;
}

.internal-team-row {
  margin-top: 20px;
}

.team-type {
  margin-top: 50px;
}

.team-title {
  z-index: 100;
  position: relative;
  width: 377px;
  height: 53px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 24px;
  background: linear-gradient(
      0deg,
      rgba(255, 183, 179, 0.25),
      rgba(255, 183, 179, 0.25)
    ),
    linear-gradient(
      180deg,
      rgba(255, 238, 237, 0.04) 0%,
      rgba(7, 57, 72, 0.124) 100%
    );
}

.team-paragraph {
  padding: 30px;
  font-size: 18px;
}

.profile-row {
  margin-bottom: 50px;
}

.profile-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.profile-pic {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
}

.exec-text {
  margin-top: 20px;
}

.centered-box {
  display: flex;
  justify-content: center;
}

@media (max-width: 1199px) {
  .about-us-card {
    width: 100vw;
    display: flex;
    justify-content: center;
  }
  .logo-row {
    visibility: hidden;
    border: 1px solid white;
  }
  .mission-vision-row {
    width: 100%;
  }
  .what-we-do-row {
    margin-top: -60px;
    width: 100%;
  }
  .card-title-parent {
    display: flex;
    justify-content: flex-start;
    margin-left: 0px;
  }
  .about-card-body {
    padding: 70px;
  }
  .mission-text {
    text-align: left;
  }
  .big-about-card-body {
    padding: 70px;
  }
  .profile-card {
    width: 50%;
  }
}

@media (max-width: 991px) {
  .what-we-do-row {
    margin-top: -20px;
    height: 470px;
  }
  .card-title-parent {
    width: 400px;
  }
  .about-card-body {
    padding: 60px;
  }
  .big-about-card-body {
    padding: 60px;
  }
}

@media (max-width: 768px) {
  .what-we-do-row {
    margin-top: -15px;
    height: 480px;
  }
  .about-card-body {
    width: 500px;
    padding: 60px;
  }
  .big-about-card-body {
    width: 500px;
    padding: 60px;
  }
  .team-title {
    width: 350px;
    height: 53px;
    margin-left: 5px;
  }
}

@media (max-width: 550px) {
  .what-we-do-row {
    margin-top: 100px;
    height: 500px;
  }
  .about-card-body {
    width: 450px;
    padding: 60px;
  }
  .big-about-card-body {
    width: 450px;
    height: 550px;
    padding: 60px;
  }
  .team-title {
    margin-left: 15px;
  }
}

@media (max-width: 500px) {
  .card-title-parent {
    width: 350px;
  }
  .about-header-text {
    font-size: 30px;
  }
  .about-us-card {
    height: 400px;
  }
  .what-we-do-row {
    margin-top: 115px;
    height: 600px;
  }
  .about-card-body {
    width: 400px;
    padding: 60px;
  }
  .big-about-card-body {
    width: 400px;
    height: 550px;
    padding: 60px;
  }
  .profile-card {
    width: 100%;
  }
}

@media (max-width: 460px) {
  .what-we-do-row {
    margin-top: 175px;
    height: 650px;
  }
  .about-card-body {
    width: 350px;
    padding: 60px;
  }
  .big-about-card-body {
    width: 350px;
    height: 600px;
    padding: 60px;
  }
}

@media (max-width: 425px) {
  .card-title-parent {
    width: 300px;
  }
  .about-us-card {
    height: 350px;
  }
  .about-header-text {
    font-size: 25px;
  }
  .what-we-do-row {
    margin-top: 115px;
    height: 550px;
  }
  .about-card-body {
    width: 325px;
    padding: 50px;
  }
  .big-about-card-body {
    width: 325px;
    height: 700px;
    padding: 50px;
  }
  .mission-text {
    font-size: 15px;
  }
  .big-card-text {
    font-size: 15px;
  }
}

@media (max-width: 400px) {
  .card-title-parent {
    width: 275px;
  }
  .about-header-text {
    font-size: 25px;
  }
  .what-we-do-row {
    margin-top: 145px;
    height: 575px;
  }
  .about-card-body {
    width: 300px;
    padding: 30px;
  }
  .big-about-card-body {
    width: 300px;
    padding: 50px;
  }
  .team-title {
    width: 300px;
    margin-left: 15px;
  }
}

@media (max-width: 375px) {
  .card-title-parent {
    width: 250px;
  }
  .about-header-text {
    font-size: 22px;
  }
  .what-we-do-row {
    margin-top: 170px;
    height: 600px;
  }
  .about-card-body {
    width: 275px;
    padding: 30px;
  }
  .big-about-card-body {
    width: 275px;
    padding: 50px;
  }
}

@media (max-width: 330px) {
  .card-title-parent {
    width: 200px;
  }
  .about-header-text {
    font-size: 18px;
  }
  .what-we-do-row {
    margin-top: 100px;
    height: 525px;
  }
  .about-card-body {
    width: 250px;
    padding: 30px;
  }
  .big-about-card-body {
    width: 250px;
    padding: 40px;
  }
  .mission-text {
    font-size: 13px;
  }
  .big-card-text {
    font-size: 13px;
  }
  .team-title {
    width: 250px;
    margin-left: 5px;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  padding: 20px 20px 20px 20px;
}

.grid-item {
  padding: 20px;
  font-size: 30px;
  text-align: center;
  display: flex;
  flex-direction: row;
  text-align: left;
  margin: 10px 30px 10px 30px;
}

.icons-image {
  height: 150px;
  width: 200px;
  margin-right: 50px;
}

.title-div {
  background: linear-gradient(
      0deg,
      rgba(240, 92, 91, 0.7),
      rgba(240, 92, 91, 0.7)
    ),
    linear-gradient(
      180deg,
      rgba(255, 238, 237, 0.04) 0%,
      rgba(7, 57, 72, 0.124) 100%
    );
  padding-right: 40px;
  padding-left: 40px;
  padding-top: 5px;
  z-index: 100;
  position: relative;
  border-radius: 10px;
}

.values-title-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media screen and (max-width: 1368px) {
  .grid-container {
    padding: 20px 20px 20px 20px;
  }
}

@media screen and (max-width: 1252px) {
  .grid-item {
    margin: 10px;
  }
  .grid-container {
    padding: 20px 10px 20px 10px;
  }
}

@media screen and (max-width: 992px) {
  .grid-container {
    grid-template-columns: auto;
    padding: 20px 10px 20px 10px;
  }
  .grid-item {
    flex-direction: column;
  }
  .text-box {
    margin-top: 40px;
    margin-bottom: 10px;
  }

  .title-div {
    align-self: self-start;
    margin-left: 40px;
  }
}

@media screen and (max-width: 700px) {
  .grid-container {
    padding: 20px 2px 20px 20px;
  }
  .title-div {
    margin-left: 50px;
  }
}

@media screen and (max-width: 400px) {
  .title-div {
    margin-left: 55px;
  }
}

@media screen and (max-width: 350px) {
  .title-div {
    margin-left: 40px;
  }
}
