/* THIS IS WHERE ALL HOME PAGE COMPONENT STYLING GOES */
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap');

.hero-container {
  position: relative;
  min-height: 830px;
}

.hero-text {
  display: flex;
  flex-direction: column;
}

.title-text {
  font-size: 48px;
  display: inline;
  color: #ffeeed;
}

.title-text-box {
  margin-top: 200px;
  margin-bottom: 100px;
  width: 60%;
}

.hexagon-box {
  display: flex;
  justify-content: center;
  border: 1px solid transparent;
  margin-top: 50px;
}

.company-image {
  display: flex;
  justify-content: center;
}

/* WHAT WE DO STYLES */

.what-we-do-container {
  /* min-height: ; */
  margin-bottom: 80px;
}

.custom-card-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.hexagon-container {
  width: 238px;
  height: 195px;
  margin-left: -110px;
  z-index: 1;
  filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.6));
}

.hexagon-image {
  width: 100%;
  height: 100%;
  transform: translateX(50%);
}

.grey-card {
  width: 635px;
  height: 195px;
  background: rgba(251, 234, 233, 0.25);
  border: 0.5px solid rgba(255, 238, 237, 0.5);
  backdrop-filter: blur(20px);
  border-radius: 10px;
  padding: 45px 30px 30px 130px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
}

/* .red-button {
  width: 150px;
  height: 50px;
  background: #f05c5b;
  border-radius: 10px;
  border-color: transparent;
  font-size: 12px;
  font-family: 'IBM Plex Mono';
  font-weight: 600;
  margin-left: 2em;
}

.red-button:active,
.red-button:hover {
  background: #9b3837 !important;
  border-color: transparent !important;
} */

.staggered-cards {
  position: relative;
  display: flex;
  flex-direction: column;
}

.background-image {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('../images/we-are-uw-pm.png');
  background-repeat: no-repeat;
  background-position: left bottom;
}

.arrow-container {
  position: absolute;
  top: 7%;
  right: 0;
  transform: translateX(-25em);
}

.fun-fact {
  position: absolute;
  top: 12%;
  right: 0;
  transform: translateX(-12em);
  width: 190px;
}

.fun-fact p {
  font-family: 'IBM Plex Mono';
}

.card-container {
  display: flex;
  align-items: center;
  margin: 30px 0;
}

.card-container:nth-child(1) {
  margin-right: auto;
}

.card-container:nth-child(3) {
  margin-left: auto;
  margin-right: auto;
}

.card-container:last-child {
  margin-left: auto;
}

@media (max-width: 1400px) {
  .arrow-container {
    transform: translateX(-15em);
  }

  .fun-fact {
    transform: translateX(-2em);
  }
}

@media (max-width: 1200px) {
  .arrow-container {
    transform: translateX(-4.5em);
  }

  .fun-fact {
    transform: translateX(3em);
  }
}

@media (max-width: 991px) {
  .background-image,
  .arrow-container,
  .fun-fact {
    display: none;
  }

  .red-circle {
    width: 210px;
    margin-right: 25px;
  }

  .title-text-box {
    width: 70%;
  }
}

@media (max-width: 991.5px) {
  .card-container:nth-child(1) {
    margin-right: 0;
  }

  .card-container:nth-child(3) {
    margin-left: 0;
    margin-right: 0;
  }

  .card-container:last-child {
    margin-left: 0;
  }

  .grey-card {
    padding: 30px 30px 30px 130px;
  }

  .hexagon-container {
    width: 270px;
  }
}

@media (max-width: 950px) {
  .staggered-cards {
    padding: 0 20px;
  }

  .hexagon-container {
    width: 262px;
    height: 175px;
  }

  .grey-card {
    width: 100%;
    height: 175px;
    padding: 40px 20px 20px 130px;
  }

  /* .red-button {
    width: 110px;
    height: 40px;
  } */

  .custom-card-container h3 {
    font-size: 22px;
  }

  .custom-card-container p {
    font-size: 14px;
  }
}

@media (max-width: 800px) {
  .title-text {
    font-size: 34px;
  }

  .red-circle {
    width: 160px;
    margin: 0;
    top: -5px;
  }

  .grey-card {
    height: 150px;
    padding: 20px 20px 20px 95px;
  }

  /* .red-button {
    width: 100px;
    height: 30px;
    border-radius: 5px;
    font-size: 10px;
  } */

  .card-container:nth-child(1),
  .card-container:nth-child(2),
  .card-container:last-child {
    margin: 20px 0;
  }

  .hexagon-container {
    width: 130px;
    height: 100px;
  }

  .hexagon-image {
    transform: translateX(75%);
  }

  .custom-card-container {
    align-items: flex-start;
  }

  .custom-card-container h3 {
    font-size: 18px;
  }

  .custom-card-container p {
    font-size: 13px;
  }
}

@media (max-width: 525px) {
  .grey-card {
    width: 100%;
    height: 150px;
    padding-top: 5px;
    padding-right: 0;
  }

  /* .red-button {
    width: 100px;
    height: 30px;
    border-radius: 5px;
    font-size: 8px;
    margin-left: 0;
  } */

  .card-container {
    flex-direction: column;
  }

  .custom-card-container h3 {
    font-size: 16px;
  }

  .custom-card-container p {
    font-size: 12px;
  }
}

@media screen and (max-width: 390px) {
  .grey-card {
    height: 170px;
  }

  .custom-card-container h3 {
    font-size: 14px;
  }

  .custom-card-container p {
    font-size: 12px;
  }
}

.file-contents {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: space-between;
  gap: 50px;
  width: 75%;
}

/* 
.file-contents {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden; 
} */
/* 
.event-img {
  max-width: 50%;
  height: auto;
  margin-right: 20px;
} */
/* 
.event-text {
  text-align: left;
} */

/* Responsive styles */
@media (max-width: 768px) {
  .file-contents {
    display: flex;

    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    overflow: hidden;
    flex-direction: column;
    padding-top: 60px;
    gap: 12px;
  }

  .event-img {
    max-width: 100%;
    height: auto;
    margin: 0 0 20px 0;
  }

  .event-text {
    flex: 1;
    text-align: center;
  }
  .background-img {
    display: block;
    width: 100%;
    height: 400px;
    /* object-fit: cover; */
  }
}

/* Existing styles */
.event-description,
.event-date {
  font-size: 20px;
  font-family: Montserrat;
}

.event-img {
  width: 20rem;
}

@media (max-width: 1024px) {
  .carousel-control-next,
  .carousel-control-prev {
    display: none;
  }

  .event-description {
    display: none;
  }

  .event-img {
    width: 15rem;
    height: 15rem;
  }
}

@media (max-width: 768px) {
  .red-circle {
    margin-right: 10px;
  }

  .title-text-box {
    width: 80%;
  }

  .event-name {
    font-size: 15px;
  }

  .event-date {
    display: none;
  }

  .event-img {
    width: 15rem;
    height: 15rem;
  }
}

.button-red-gradiant {
  /* position: absolute; */
  box-sizing: border-box;
  width: 150px;
  height: 52px;
  left: 134px;
  top: 597px;

  background: linear-gradient(
    180deg,
    rgba(240, 92, 91, 0.75) 0%,
    rgba(240, 92, 91, 0.2325) 100%
  );
  border: 1px solid rgba(240, 92, 91, 0.1);
  box-shadow:
    0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 0px 12px 7px rgba(46, 56, 68, 0.25);
  border-radius: 10px;

  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height */

  color: #fdeceb;
}
.button-red-gradiant:active,
.button-red-gradiant:hover {
  background: #9b3837 !important;
  border-color: transparent !important;
}

.button-container {
  max-width: fit-content;
}

.button-transparent {
  box-sizing: border-box;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;

  color: #fdeceb;

  width: 159px;
  height: 52px;
  left: 347px;
  top: 597px;
  background: transparent;

  border: 0.5px solid #ffeeed;
  box-shadow: inset 0px 0px 5px 2px #ffeeed;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
}

@media (max-width: 767px) {
  .button-red-gradiant {
    margin-right: 20px;
  }

  .button-transparent {
    margin-left: 20px;
  }
}

#statistic_num {
  font-family: 'Syne';
  font-weight: 720;
  font-size: 36px;
}

#statistic_word {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 20px;
}

.file-contents p {
  font-size: 18px;
  font-family: Montserrat;
}

.desktop-hex {
  width: auto;
  height: auto;
  position: absolute;
  right: -4.7%;
  top: -2%;
  scale: 1.05;
  pointer-events: none;
  float: right;
  z-index: 0;
}

.grad-cap-hex {
  width: auto;
  height: auto;
  position: absolute;
  right: 32.6%;
  top: 3.3%;
  scale: 1.05;
  float: right;
  z-index: 2;
}

.mic-hex {
  width: auto;
  height: auto;
  position: absolute;
  right: 50.6%;
  top: 71.7%;
  scale: 1.1;
  float: right;
  z-index: 2;
}

.pencil-hex {
  width: auto;
  height: auto;
  position: absolute;
  right: 22.6%;
  top: 46.5%;
  scale: 1.1;
  float: right;
  z-index: 2;
}

.headphone-hex {
  width: auto;
  height: auto;
  position: absolute;
  right: 4.25%;
  top: 11.75%;
  scale: 1.1;
  float: right;
  z-index: 2;
}

.bow-hex {
  width: auto;
  height: auto;
  position: absolute;
  right: 4.2%;
  top: 63.3%;
  scale: 1.1;
  float: right;
  z-index: 2;
}

.flower-hex {
  width: auto;
  height: auto;
  position: absolute;
  right: 23.2%;
  top: 79.8%;
  scale: 1.1;
  pointer-events: none;
  float: right;
  z-index: 2;
}

.overlay-container {
  position: relative;
}

.image-container-mic-hex-popover {
  position: absolute;
  margin-left: 75px;
  z-index: 5;
}

.image-container-bow-hex-popover {
  position: absolute;
  margin-left: -140px;
  z-index: 3;
}

.image-container-headphone-hex-popover {
  position: absolute;
  margin-left: -80px;
  z-index: 3;
}

.image-container-grad-cap-hex-popover {
  position: absolute;
  margin-top: 50px;
  z-index: 3;
}

.text-popover-hex {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  padding: 10px;
  text-align: left;
  width: 90%;
  max-width: 400px;
}

.text-popover-grad-cap-hex {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  padding: 10px;
  text-align: left;
  width: 90%;
  max-width: 400px;
}

@media (max-width: 1399px) {
  .desktop-hex {
    width: auto;
    height: auto;
    position: relative;
    margin-right: 50px;
    right: -10%;
    top: -2%;
    scale: 1.05;
    pointer-events: none;
    float: right;
    z-index: 1;
  }

  .grad-cap-hex {
    width: auto;
    height: auto;
    position: absolute;
    margin-right: 50px;
    right: 38.5%;
    top: 3.3%;
    scale: 1.05;
    float: right;
    z-index: 2;
  }

  .mic-hex {
    width: auto;
    height: auto;
    position: absolute;
    margin-right: 50px;
    right: 59.5%;
    top: 71.7%;
    scale: 1.1;
    float: right;
    z-index: 1;
  }

  .pencil-hex {
    width: auto;
    height: auto;
    position: absolute;
    margin-right: 50px;
    right: 27%;
    top: 46.5%;
    scale: 1.1;
    float: right;
    z-index: 2;
  }

  .headphone-hex {
    width: auto;
    height: auto;
    position: absolute;
    margin-right: 50px;
    right: -5.2%;
    top: 20.5%;
    scale: 1.1;
    float: right;
    z-index: 2;
  }

  .bow-hex {
    width: auto;
    height: auto;
    position: absolute;
    margin-right: 50px;
    right: 6%;
    top: 63.3%;
    scale: 1.1;
    float: right;
    z-index: 2;
  }

  .flower-hex {
    width: auto;
    height: auto;
    position: absolute;
    margin-right: 50px;
    right: 27.8%;
    top: 79.8%;
    scale: 1.1;
    pointer-events: none;
    float: right;
    z-index: 2;
  }

  .image-container-headphone-hex-popover {
    position: relative;
    margin-left: -8.3%;
    margin-bottom: -1.7%;
    z-index: 3;
  }
}

@media (max-width: 1199px) {
  .hexagon-box {
    display: flex;
    width: 100vw;
  }

  .desktop-hex {
    width: auto;
    height: auto;
    position: absolute;
    margin-right: 50px;
    right: -10%;
    top: -2%;
    scale: 1;
    pointer-events: none;
    float: right;
    z-index: 0;
  }

  .grad-cap-hex {
    width: auto;
    height: auto;
    position: absolute;
    margin-right: 50px;
    right: 47%;
    top: 3.3%;
    scale: 1.05;
    float: right;
    z-index: 2;
  }

  .mic-hex {
    width: auto;
    height: auto;
    position: absolute;
    margin-right: 50px;
    right: 72%;
    top: 71.7%;
    scale: 1.1;
    float: right;
    z-index: -1;
  }

  .pencil-hex {
    width: auto;
    height: auto;
    position: absolute;
    margin-right: 50px;
    right: 33.2%;
    top: 46.5%;
    scale: 1.1;
    float: right;
    z-index: 2;
  }

  .headphone-hex {
    width: auto;
    height: auto;
    position: absolute;
    margin-right: 50px;
    right: -5.2%;
    top: 20.5%;
    scale: 1.1;
    float: right;
    z-index: 2;
  }

  .bow-hex {
    width: auto;
    height: auto;
    position: absolute;
    margin-right: 50px;
    right: 8%;
    top: 63.3%;
    scale: 1.1;
    float: right;
    z-index: 2;
  }

  .flower-hex {
    width: auto;
    height: auto;
    position: absolute;
    margin-right: 50px;
    right: 34%;
    top: 79.8%;
    scale: 1.1;
    pointer-events: none;
    float: right;
    z-index: 2;
  }

  .image-container-mic-hex-popover {
    position: relative;
    z-index: 3;
  }

  .image-container-bow-hex-popover {
    position: relative;
    z-index: 3;
  }

  .image-container-headphone-hex-popover {
    position: relative;
    z-index: 3;
  }

  .image-container-grad-cap-hex-popover {
    position: absolute;
    z-index: 3;
  }
}

@media (max-width: 991px) {
  .desktop-hex {
    width: auto;
    height: auto;
    position: absolute;
    right: -50%;
    top: -2%;
    scale: 1;
    pointer-events: none;
    float: right;
    z-index: 0;
  }

  .grad-cap-hex {
    display: none;
  }

  .mic-hex {
    display: none;
  }

  .pencil-hex {
    display: none;
  }

  .headphone-hex {
    display: none;
  }

  .bow-hex {
    display: none;
  }

  .flower-hex {
    display: none;
  }

  .hex-popover-img {
    scale: -1 1;
  }
}

@media (max-width: 767px) {
  .desktop-hex {
    width: 100vw;
    height: auto;
    position: fixed;
    overflow: hidden;
    right: -20%;
    top: -4%;
    scale: 0.75;
    pointer-events: none;
    float: right;
    z-index: 0;
  }
  .grad-cap-hex {
    display: none;
  }

  .mic-hex {
    display: none;
  }

  .pencil-hex {
    display: none;
  }

  .headphone-hex {
    display: none;
  }

  .bow-hex {
    display: none;
  }

  .flower-hex {
    display: none;
  }
}

@media (max-width: 650px) {
  .hexagon-box {
    display: flex;
    width: 100vw;
    height: 100vh;
    position: fixed;
    align-items: left;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }

  .hero-text {
    margin-left: 15%;
  }

  .desktop-hex {
    display: none;
  }
}
