.events-container {
  position: relative;
  min-height: 830px;
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.figCaption {
  position: absolute;
  top: 2rem;
  margin-top: 200px;
  margin-bottom: 100px;
  margin-right: 20%;
  width: 75%;
}

.title-text {
  font-size: 48px;
  display: inline;
  color: #ffeeed;
}

.prodcon_forward_arrow,
.prodcon_prev_arrow,
.modal_prev_arrow,
.modal_forward_arrow {
  position: absolute;
  top: 43%;
  transform: translateY(-50%);
  cursor: pointer;
  background-color: rgba(242, 243, 244, 0);
  color: white;
  border: none;
}

.modal_prev_arrow,
.modal_forward_arrow {
  top: 41%;
}

.prodcon_forward_arrow {
  right: -4%;
}

.prodcon_prev_arrow {
  left: -3%;
}

.modal_forward_arrow {
  right: 0%;
}

.video_carousel_text {
  margin-top: 3vh;
  font: Syne;
  font-weight: 700;
  font-size: 24px;
  line-height: 28.8px;
  margin-bottom: 30px;
}

.close-button {
  position: absolute;
  top: 3px;
  right: 3px;
  cursor: pointer;
}

.modal-content {
  padding-bottom: 100px;
}

@media (max-width: 1199px) {
  .hex-container {
    margin-left: -30%;
  }
}
@media (max-width: 995px) {
  .hex-container {
    margin-left: -40%;
  }
  .figCaption {
    width: 85%;
  }
  .title-text {
    font-size: 38px;
  }
}
@media (max-width: 800px) {
  .hex-container {
    margin-left: -50%;
  }

  .modal_forward_arrow {
    right: -7.5%;
  }

  .modal_prev_arrow {
    left: -5%;
  }
}

@media (max-width: 775px) {
  .hex-container {
    margin-left: -60%;
  }
  .figCaption {
    width: 75%;
  }
  .title-text {
    font-size: 38px;
  }

  .video_carousel_text {
    text-align: center;
    font: Syne;
    font-weight: 700;
    font-size: 14px;
    line-height: 28.8px;
    margin-bottom: 1em;
    color: #ffeeed;
  }
}

@media (max-width: 700px) {
  .hex-container {
    margin-left: -60%;
  }

  .modal_prev_arrow,
  .modal_forward_arrow {
    top: 32%;
  }
}

.title-section {
  margin-bottom: 40px;
}

.main-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.upcoming-events-post {
  width: 375px;
  height: 375px;
}

.upcoming-event-text {
  width: 100%;
  margin-right: 10rem;
}

@media (max-width: 1620px) {
  .container-full {
    margin-right: 10%;
    margin-left: 10%;
  }
}

@media (max-width: 1200px) {
  .container-full {
    margin-right: 5%;
    margin-left: 5%;
  }
}

@media (max-width: 991px) {
  .main-section {
    display: flex;
    flex-direction: column;
  }

  .upcoming-event-text {
    margin-right: 0;
  }

  .title-section {
    text-align: left;
  }

  .upcoming-events-post {
    width: 300px;
    height: 300px;
  }

  .about,
  .description,
  .sub-title,
  .inner-section ul li {
    font-size: 90%;
  }
}

@media (max-width: 550px) {
  inner-section {
    width: 100%;
  }

  .upcoming-events-post {
    width: 250px;
    height: 250px;
  }
}

.section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@import 'react-alice-carousel/lib/alice-carousel.css';

.horizontal-images-container {
  margin-left: 2em;
  margin-right: 2em;
  margin-bottom: 30px;
  justify-content: center;
  display: flex;
}

.horizontal-image-item {
  flex: 0 0 auto;
  justify-content: center;
  margin: 0 auto;
}

.resized-image {
  margin: 0 auto;
  justify-content: center;
  display: flex;
  width: 90%;
  height: auto;
  cursor: pointer;
}

.alice-carousel__stage-item {
  padding: 0 0 0 0;
  margin: 0 0 0 0;
}

.line {
  width: 100%;
}

.inner-section {
  margin-bottom: 30px;
}

.about {
  font-weight: bold;
  margin-bottom: 5px;
}

.sub-title {
  margin-top: 30px;
  margin-bottom: 5px;
}

.main-text-event {
  color: #ffeeed;
  font-family: Syne;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 1em;
}

.video-responsive {
  margin-left: 2.8em;
  margin-right: 2.8em;
  margin-bottom: 1em;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.individual-team-container {
  position: relative;
  padding-top: 80px;
}
